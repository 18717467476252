/** @jsx jsx */
import { jsx } from "@emotion/core"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import { Col, Container, Row } from "react-bootstrap"
import Section from "../components/Section"
import styled from "@emotion/styled"

const Reclamation = () => (
  <Layout>
    <SEO
      title="En savoir plus sur notre service de réclamation ou médiation"
      description="Simulassur - Réclamations clients ou non clients - Comment faire ?"
    />
    <Section className="pt-5 pb-2" css={{ marginTop: "60px" }}>
      <Container className="content">
        <h1>En savoir plus sur notre service de réclamation ou médiation</h1>
        <hr />
      </Container>
    </Section>
    <Section className="pt-0 content">
      <Container>
        <h2 style={{ fontWeight: "lighter" }}>
          Vous n’êtes pas satisfait de nos services ?
        </h2>
            <Text>
              Retrouvez sur cette page les informations concernant le traitement
              des réclamations et médiations. Sachez que le traitement de la
              réclamation est identique que vous soyez client ou non client.
            </Text>
        <Row>
          <Col className="my-2">
            <h3 style={{ fontWeight: "normal", fontSize: "1.5em" }}>
              Comment se déroule le traitement de ma réclamation ?
            </h3>
          </Col>
        </Row>
            <div>
              <h3 style={{ fontWeight: "lighter", fontSize: "1.3em" }}>
                {" "}
                Réclamation - Vous n’êtes pas satisfait ?
              </h3>
              <Text>
                Si vous n’étiez pas satisfait de notre prestation, nous vous
                invitons à nous le faire savoir. A cet effet, nous avons mis en
                place la procédure de gestion des réclamations suivante.
              </Text>
              <Text>
                Vous pouvez vous rapprocher de votre interlocuteur habituel, qui
                sera à votre écoute pour résoudre tout problème que vous
                pourriez lui soumettre.
              </Text>
              <Text>
                Vous pouvez également, si vous le souhaitez, nous contacter par
                mail en adressant votre réclamation à{" "}
                <LinkTo href="mailto:reclamation@simulassur.fr">
                  reclamation@simulassur.fr
                </LinkTo>
              </Text>
              <Text>
                Nous ferons notre possible pour vous apporter une réponse dans
                les plus brefs délais.
              </Text>
              <Text>
                Si nous ne pouvons vous répondre sous 10 jours, nous accuserons
                réception de votre réclamation sous ce délai, par un courrier ou
                un mail.
              </Text>
              <Text>
                Nous nous engageons dans ce cas à vous apporter une réponse sous
                un délai maximal de 2 mois. Si toutefois le problème rencontré
                était particulièrement complexe et ne pouvait être traité dans
                ce délai, nous vous tiendrons régulièrement informé de l’état
                d’avancement de votre dossier.
              </Text>
            </div>
            <Text>Nos étapes de la réclamation en illustration :</Text>
            <img
              src={require("../images/reclamation/reclamation-steps.png")}
              alt="étapes de reclamation en illustration"
            />

        <Row>
          <Col className="mt-4">
            <h3
              style={{ fontWeight: "500", fontSize: "1.5em" }}
              className="mb-2"
            >
              Comment se déroule le traitement de la médiation ?{" "}
            </h3>
            <Text style={{ fontWeight: "500" }}>
              Vous n’êtes pas satisfait du traitement de votre réclamation ou
              vous n’avez pas eu de réponse ?
            </Text>
            <Text>
              À l’issue d’un délai de deux mois à compter de l’envoi de votre
              première réclamation écrite, vous pouvez saisir le CMAP (Centre de
              Médiation et d’Arbitrage de Paris) soit :{" "}
            </Text>
            <ul>
              <li>
                via le formulaire à disposition sur le site du CMAP à l’adresse
                <LinkTo href="http://www.cmap.fr/consommateurs" target="_blank" className="text-break">
                  http://www.cmap.fr/consommateurs
                </LinkTo>
                ,
              </li>
              <li>
                par courrier postal à l’adresse - CMAP Service Médiation de la
                consommation, 39 avenue Franklin Roosevelt, 75008 Paris.
              </li>
            </ul>
            <Text>
              Vous devez impérativement préciser au CMAP l’objet du litige et
              lui adresser toutes les pièces du dossier comme indiqué dans le
              formulaire de saisine. À défaut, la saisine ne pourra être prise
              en compte. Cette saisine peut être faite en français ou en
              anglais.
            </Text>
          </Col>
        </Row>
      </Container>
    </Section>
  </Layout>
)

const LinkTo = styled.a`
  color: blue;
  text-decoration: underline;
  &:hover {
    color: black;
  }
`

const Text = styled.p`
  text-align: justify;
`

export default Reclamation
